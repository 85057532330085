import {CLOSE_INVITE_USER_MODAL, OPEN_INVITE_USER_MODAL} from '../actions/contactsModalsAction'

export const isInviteNewUserModalOpen = (state = null, action) => {
  switch (action.type) {
    case OPEN_INVITE_USER_MODAL:
    case CLOSE_INVITE_USER_MODAL:
      return action.isInviteNewUserModalOpen
    default:
      return state
  }
}
