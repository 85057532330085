import React from 'react'
import classes from './styles.module.scss'

export const ContactsTableHeader: React.FC = ({}) => {

  return (
    <div style={{background: '#F7FAFE'}}>
      <div className={classes.contactItemHeader}>
        <div className={classes.firstAndLastName}>First name and Last name</div>
        <div className={classes.userName}>Username</div>
        <div className={classes.email}>Email address</div>
        <div className={classes.phone}>Phone number</div>
        <div className={classes.phone}>Number of logins</div>
        <div className={classes.phone}>Total interaction time</div>
        <div className={classes.status}>User status</div>
      </div>
    </div>
  )
}
