import React, {MouseEvent} from 'react'
import {ChatBotDeliverablesButtonsWithResult} from './ChatBotDeliverablesButtonsWithResult'
import classes from './styles.module.scss'

interface ChatBotDeliverablesBlockWithResultProps {
  title: string
  result: string
  viewUrl?: string
  downloadUrl?: string
  openPDFHandler: (e: MouseEvent<HTMLAnchorElement>, viewUrl: string) => void
}

export const ChatBotDeliverablesBlockWithResult: React.FC<ChatBotDeliverablesBlockWithResultProps> = ({
  title,
  result,
  viewUrl,
  downloadUrl,
  openPDFHandler
}) => {

  return (
    <div className={classes.sectionWrapper}>
      <div className={classes.title}>
        {title}
      </div>
      <ChatBotDeliverablesButtonsWithResult
        result={result}
        viewUrl={viewUrl}
        downloadUrl={downloadUrl}
        openPDFHandler={openPDFHandler}
      />
    </div>
  )
}
