import React from 'react'
import {InfoFieldValue} from './InfoFieldValue'
import {InfoFieldTitle} from './InfoFieldTitle'
import classes from './styles.module.scss'

interface InfoFieldProps {
  title: string
  value: string
}

export const InfoField: React.FC<InfoFieldProps> = ({title, value}) => {
  return (
    <div className={classes.infoFiledWrapper}>
      <InfoFieldTitle title={title}/>
      <InfoFieldValue value={value}/>
    </div>
  )
}
