/* tslint:disable */
/* eslint-disable */
export enum ResultStatus {
    NEGATIVE = 'NEGATIVE',
    VUS = 'VUS',
    POSITIVE = 'POSITIVE'
}

export enum GtKitOption {
    CONFIRMED = 'CONFIRMED',
    REJECTED = 'REJECTED'
}

export interface ContactInfo {
    firstAndLastName: string;
    username: string;
    email: string;
    phone: string;
    riskAssessmentQuestionnaireLink?: string;
    familyPedigreeQuestionnaireLink?: string;
    familyTreeLink?: string;
    readinessRulerLink?: string;
    nameFromArguments?: string;
    surnameFromArguments?: string;
    emailFromArguments?: string;
    phoneFromArguments?: string;
    addressFromArguments?: string;
    resultsDeliverWay?: string;
    insuranceCardPhotos?: string[];
    isRequiredStatus: boolean;
}

export interface GtKitInfo {
    gtKitOption: GtKitOption
}

export interface ResultsDeliveryInfo {
    isResultsDeliveryScheduled: boolean;
    resultStatus: ResultStatus;
    isResultsDeliveryConfirmed: boolean
    resultsDeliveryConfirmationTimestamp: string;
    positiveResultsDeliveryConfirmationTimestamp: string;
}
