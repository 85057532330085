import React from 'react'
import {connect} from 'react-redux'
import {NavLink, withRouter} from 'react-router-dom'
import {StatusCell} from '../cell/StatusCell'
import {HOME_PATH} from '../../../../../configs'
import {LifeCenterUserTableRow} from '../../../api/model/LifeCenterUserTableView'
import classes from './styles.module.scss'

interface ContactsTableBodyProps {
  match: any
  contacts: LifeCenterUserTableRow[]
}

const ContactsTableBody: React.FC<ContactsTableBodyProps> = ({match, contacts}) => {

  const preparePhoneNumber = (phoneNumber) => {
    return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`
  }

  const getRowStyle = (index, c: LifeCenterUserTableRow) => {
    if (c.statusTitle == 'User Assigned') {
      return {background: 'rgba(238, 238, 238)'}
    } else {
      if ((index + 1) % 2 === 1) {
        return {background: 'rgba(90, 152, 247, 0.1)'}
      } else {
        return {background: '#F7FAFE'}
      }
    }
  }

  return (
    <>
      {contacts != null && contacts
        .map((c, index) => (
          <NavLink
            to={`${HOME_PATH}/bot/${match.params.botId}/contacts/${c.id}`}
            key={index}
            style={getRowStyle(index, c)}
            className={classes.contactItem}>
            <div className={classes.firstAndLastName}>{c.firstAndLastName}</div>
            <div className={classes.userName}>{c.userName}</div>
            <div className={classes.email}>{c.email}</div>
            <div className={classes.phone}>{c.phoneNumber ? preparePhoneNumber(c.phoneNumber) : 'NONE'}</div>
            <div className={classes.numberOfLogins}>{c.numberOfLogins ? c.numberOfLogins : '0'}</div>
            <div className={classes.totalInteractionTime}>{c.totalInteractionTime}</div>
            <div className={classes.status}>
              <StatusCell
                statusTitle={c.statusTitle}
                statusColor={c.statusColor}
              />
            </div>
          </NavLink>
        ))}
    </>
  )
}

const mapStateToProps = state => ({
  contacts: state.contacts
})

export default withRouter(connect(mapStateToProps)(ContactsTableBody))
