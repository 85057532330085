import React from 'react'
import classes from './styles.module.scss'

interface InfoFieldValueProps {
  value: string
}

export const InfoFieldValue: React.FC<InfoFieldValueProps> = ({value}) => {
  return (
    <div className={classes.infoFiledValue}>
      {value}
    </div>
  )
}
