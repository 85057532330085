import React, {useRef, useState} from 'react'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'
import {FormControlLabel} from '@material-ui/core'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import {ArrowDownIcon} from '../common/icons/ArrowDownIcon'
import moment from 'moment-timezone'
import {selectedFile} from '../../../flowBuilder/api/fileService'

import DateRangePicker from 'react-bootstrap-daterangepicker'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import {UploadFileIcon} from '../common/icons/UploadFileIcon'
import {alertError} from '../../../../api'
import {PdfFileIcon} from '../common/icons/PdfFileIcon'
import {DeleteFileIcon} from '../common/icons/DeleteFileIcon'
import {Timeit} from 'react-timeit'
import {scheduleResultDelivery} from '../../api/contactInfoApi'
import {ResultStatus} from '../../api/model/ContactInfo'
import classes from './styles.module.scss'

interface ScheduleResultsDeliveryModalProps {
  match: any
  onClose: () => void
}

export const ScheduleResultsDeliveryModal: React.FC<ScheduleResultsDeliveryModalProps> = ({match, onClose}) => {
  const fileInputRef = useRef<any>(null)
  const [didUserGetGeneticTesting, setDidUserGetGeneticTesting] = useState<boolean>(true)
  const [resultsStatus, setResultsStatus] = useState<ResultStatus>(ResultStatus.NEGATIVE)
  const [dateOfGeneticTesting, setDateOfGeneticTesting] = useState<moment.Moment>(moment.tz('America/New_York'))
  const [dateOfGeneticTestingSelected, setDateOfGeneticTestingSelected] = useState<boolean>(false)
  const [scheduleResultsDelivery, setScheduleResultsDelivery] = useState<moment.Moment>(moment.tz('America/New_York'))
  const [scheduleResultsDeliverySelected, setScheduleResultsDeliverySelected] = useState<boolean>(false)
  const [time, setTime] = useState<string>(null)
  const [timeSelected, setTimeSelected] = useState<boolean>(false)
  const [selectedUserFile, setSelectedUserFile] = useState(null)
  const [selectedFileUrl, setSelectedFileUrl] = useState<string>(null)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [isDateOfGeneticTestingValid, setIsDateOfGeneticTestingValid] = useState<boolean>(true)
  const [isScheduleResultsDeliveryValid, setIsScheduleResultsDeliveryValid] = useState<boolean>(true)
  const [isTimeValid, setIsTimeValid] = useState<boolean>(true)
  const [isFileValid, setIsFileValid] = useState<boolean>(true)
  const [isSubmitButtonWasClicked, setIsSubmitButtonWasClicked] = useState<boolean>(false)
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(false)
  const [dateOfGeneticTestingPickerOpen, setDateOfGeneticTestingPickerOpen] = useState<boolean>(false)
  const [scheduleResultDeliveryPickerOpen, setScheduleResultDeliveryPickerOpen] = useState<boolean>(false)
  const [timePickerOpen, setTimePickerOpen] = useState<boolean>(false)
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>(null)

  const validateFields = (
    didUserGetGeneticTesting,
    resultsStatus,
    dateOfGeneticTestingSelected,
    scheduleResultsDeliverySelected,
    timeSelected,
    isSubmitButtonWasClicked
  ) => {

    if (isSubmitButtonWasClicked) {
      setIsDateOfGeneticTestingValid(dateOfGeneticTestingSelected)
      setIsScheduleResultsDeliveryValid(scheduleResultsDeliverySelected)
      setIsTimeValid(timeSelected)
      setIsFileValid(true)
    }

    setDisableSubmitButton(
      (!dateOfGeneticTestingSelected || !scheduleResultsDeliverySelected || !timeSelected)
      && isSubmitButtonWasClicked
    )

    return didUserGetGeneticTesting && dateOfGeneticTestingSelected && scheduleResultsDeliverySelected &&
          timeSelected && isFileValid
  }

  const handleSubmitButtonClick = e => {
    const isSubmitButtonWasClicked = true
    setIsSubmitButtonWasClicked(isSubmitButtonWasClicked)

    const isAllFieldsValid = validateFields(didUserGetGeneticTesting, resultsStatus,
      dateOfGeneticTestingSelected, scheduleResultsDeliverySelected, timeSelected,
      isSubmitButtonWasClicked)

    if (isAllFieldsValid) {
      scheduleResultDelivery({
        dateOfGeneticTestingDate: dateOfGeneticTesting,
        resultDeliveryDate: scheduleResultsDelivery,
        resultDeliveryTime: time,
        fileUrl: selectedFileUrl,
        resultStatus: resultsStatus,
      }, match.params.botId, match.params.contactId)
      onClose()
    }
  }

  const handleNegativeResultBtnClick = () => {
    setResultsStatus(ResultStatus.NEGATIVE)
  }

  const handleVUSResultBtnClick = () => {
    setResultsStatus(ResultStatus.VUS)
  }

  const handlePositiveResultBtnClick = () => {
    setResultsStatus(ResultStatus.POSITIVE)
    clearInterval(intervalId)
    handleDeleteFile()
  }

  const setDateOfGeneticTestingDate = (event, picker) => {
    setDateOfGeneticTesting(moment.tz(picker.startDate, 'America/New_York'))
    setDateOfGeneticTestingSelected(true)
    setIsDateOfGeneticTestingValid(true)

    validateFields(
      didUserGetGeneticTesting,
      resultsStatus,
      true,
      scheduleResultsDeliverySelected,
      timeSelected,
      isSubmitButtonWasClicked
    )
  }

  const setScheduleResultsDeliveryDate = (event, picker) => {
    setScheduleResultsDelivery(moment.tz(picker.startDate, 'America/New_York'))
    setScheduleResultsDeliverySelected(true)
    setIsScheduleResultsDeliveryValid(true)

    validateFields(
      didUserGetGeneticTesting,
      resultsStatus,
      dateOfGeneticTestingSelected,
      true,
      timeSelected,
      isSubmitButtonWasClicked
    )
  }

  const setTimeDate = (time) => {
    setTime(time)
    setTimeSelected(true)
    setIsTimeValid(true)
    setTimePickerOpen(false)

    validateFields(
      didUserGetGeneticTesting,
      resultsStatus,
      dateOfGeneticTestingSelected,
      scheduleResultsDeliverySelected,
      true,
      isSubmitButtonWasClicked
    )
  }

  const parseDate = (timestamp) => {
    const date = new Date(timestamp)
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
  }

  const handleUpload = () => {
    // Simulate file upload progress
    const interval = setInterval(() => {
      setUploadProgress(prev => {
        const progress = prev + prev * 0.5 + 1
        if (progress >= 100) {
          clearInterval(interval)
          return 100
        }
        return progress
      })
    }, 300)
    setIntervalId(interval)
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file && file.size < 10 * 1024 * 1024) {
      handleUpload()
      setSelectedUserFile(file)
      setIsFileValid(true)

      const fileToUpload = new FormData()
      fileToUpload.append('file', file)
      selectedFile(fileToUpload).then(res => {
        setSelectedFileUrl(res.url)
      })
    } else {
      alertError('File size exceeds the 10MB limit.')
    }
    validateFields(didUserGetGeneticTesting,
      resultsStatus, dateOfGeneticTestingSelected, scheduleResultsDeliverySelected,
      timeSelected, isSubmitButtonWasClicked)
  }

  const prepareFileSize = (fileSize) => {
    if (fileSize / 1024 > 1000) {
      return `${(fileSize / 1024 / 1024).toFixed(1)}MB`
    }
    return `${(fileSize / 1024).toFixed()}KB`
  }

  const handleDeleteFile = () => {
    setSelectedUserFile(null)
    setUploadProgress(0)

    validateFields(didUserGetGeneticTesting,
      resultsStatus, dateOfGeneticTestingSelected, scheduleResultsDeliverySelected,
      timeSelected, isSubmitButtonWasClicked)
  }

  const handleRadioGroupChange = (e) => {
    const values = e.target.value === 'true'
    setDidUserGetGeneticTesting(values)
    if (!values) {
      onClose()
    }
  }

  return (
    <div className={classes.modalWrapper}>
      <h2 className={classes.header}>
                    Schedule results delivery
      </h2>
      <div>
        <span className={classes.geneticTestingTitle}>Did the user get genetic testing?</span>
        <RadioGroup
          className={classes.radioGroup}
          value={didUserGetGeneticTesting ? 'true' : 'false'}
          onChange={e => handleRadioGroupChange(e)}>
          <FormControlLabel
            value={'true'}
            control={<Radio
              color={'primary'}
            />}
            label="Yes"
          />
          <FormControlLabel
            value={'false'}
            control={<Radio
              color={'primary'}
            />}
            label="No"
          />
        </RadioGroup>
      </div>
      <div className={classes.resultBlock}>
        <span className={classes.resultsTitle}>What are the results</span>
        <div className={classes.resultBtnsWrapper}>
          <div
            className={resultsStatus === ResultStatus.NEGATIVE ?
              classes.negativeResultBtn : classes.notSelectedResultBtn}
            onClick={handleNegativeResultBtnClick}>
                            Negative
          </div>
          <div
            className={resultsStatus === ResultStatus.VUS ?
              classes.vusResultBtn : classes.notSelectedResultBtn}
            onClick={handleVUSResultBtnClick}>
                            VUS
          </div>
          <div
            className={resultsStatus === ResultStatus.POSITIVE ?
              classes.positiveResultBtn : classes.notSelectedResultBtn}
            onClick={handlePositiveResultBtnClick}>
                            Positive
          </div>
        </div>
      </div>
      <div className={classes.dateSelectionWrapper}>
        <span className={classes.dateTitle}>Date of genetic testing</span>
        <DateRangePicker
          singleDatePicker
          maxDate={moment.tz('America/New_York')}
          className={classes.dateRangePickerContainer}
          startDate={dateOfGeneticTesting}
          endDate={dateOfGeneticTesting}
          onApply={setDateOfGeneticTestingDate}>
          <div className={classes.chooseDate}
            onClick={e =>
              setDateOfGeneticTestingPickerOpen(!dateOfGeneticTestingPickerOpen)}>
            <span className={isDateOfGeneticTestingValid ?
              classes.chooseDatePlaceholder : classes.chooseDatePlaceholderError}>
              {dateOfGeneticTestingSelected ? parseDate(dateOfGeneticTesting) : 'Choose date'}
            </span>
            <div className={classes.iconWrapper}>
              <ArrowDownIcon/>
            </div>
          </div>
        </DateRangePicker>
      </div>
      <div className={classes.dateSelectionWrapper}>
        <span className={classes.dateTitle}>Schedule results delivery</span>
        <DateRangePicker
          singleDatePicker
          minDate={moment.tz('America/New_York')}
          className={classes.dateRangePickerContainer}
          startDate={scheduleResultsDelivery}
          endDate={scheduleResultsDelivery}
          onApply={setScheduleResultsDeliveryDate}>
          <div className={classes.chooseDate}
            onClick={e =>
              setScheduleResultDeliveryPickerOpen(!scheduleResultDeliveryPickerOpen)}>
            <span className={isScheduleResultsDeliveryValid ?
              classes.chooseDatePlaceholder : classes.chooseDatePlaceholderError}>
              {scheduleResultsDeliverySelected ? parseDate(scheduleResultsDelivery) : 'Choose date'}
            </span>
            <div className={classes.iconWrapper}>
              <ArrowDownIcon/>
            </div>
          </div>
        </DateRangePicker>
      </div>
      <div className={classes.dateSelectionWrapper}>
        <span className={classes.dateTitle}>Time</span>
        <div className={classes.chooseTimeWrapper}
          onClick={e => setTimePickerOpen(!timePickerOpen)}>
          <span className={isTimeValid ?
            classes.chooseDatePlaceholder : classes.chooseDatePlaceholderError}>
            {timeSelected ? time : 'Choose time'}</span>
          <div className={classes.iconWrapper}>
            <ArrowDownIcon/>
          </div>
        </div>
        {timePickerOpen && (
          <div className={classes.timeSelectorWrapper}>
            <Timeit defualtValue={time ? time : '00:00'} onChange={e => setTime(e)}/>
            <div onClick={e => setTimeDate(time)}
              style={{color: '#1658F3', paddingTop: '24px', paddingBottom: '12px', cursor: 'pointer'}}>Done</div>
          </div>
        )}
      </div>
      {(resultsStatus !== ResultStatus.POSITIVE && !selectedUserFile) && (
        <div className={classes.selectFileWrapper} onClick={() => fileInputRef.current.click()}>
          <div className={classes.uploadFileImageWrapper}>
            <UploadFileIcon/>
          </div>
          <div className={classes.uploadFileRightSection}>
            <div className={classes.uploadFileTextWrapper}>
              <span className={classes.uploadFileTextBold}>
                Select a PDF with lab results or drag and drop here
              </span>
              <span className={classes.uploadFileTextLight}>
                File size should be no more than 10MB
              </span>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              style={{display: 'none'}}
              accept=".pdf"
              onChange={handleFileChange}
            />
            <div className={isFileValid ? classes.selectFileBtn : classes.selectFileBtnError}>
                                Select file
            </div>
          </div>
        </div>
      )}
      {uploadProgress !== 0 && (
        <div className={classes.uploadedFileWrapper}>
          <span className={classes.uploadedFileTitleText}>
            {uploadProgress === 100 ? 'File uploaded' : 'File uploading'}
          </span>
          <div className={classes.fileUploadingProgressWrapper}>
            <PdfFileIcon/>
            <div className={classes.fileUploadingProgressRightSection}>
              <span className={classes.fileUploadingProgressText}>{selectedUserFile?.name}</span>
              {uploadProgress !== 100 && (
                <div
                  style={{
                    width: `${uploadProgress}%`,
                    height: '2px',
                    backgroundColor: '#5A98F7',
                    borderRadius: '4px',
                  }}/>
              )}
            </div>
            {uploadProgress === 100 && (
              <div className={classes.fileInfoWrapper}>
                <span className={classes.fileSize}>{prepareFileSize(selectedUserFile?.size)}</span>
                <div
                  onClick={handleDeleteFile}
                  style={{cursor: 'pointer'}}
                >
                  <DeleteFileIcon/>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <SubmitButton
        disabled={disableSubmitButton}
        styles={{
          width: '97px',
          height: '48px',
          margin: '30px auto 0',
          display: 'block'
        }}
        onClick={handleSubmitButtonClick}
        title={'Schedule'}
      />
    </div>
  )
}
