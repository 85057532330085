import { store } from '../../../index'

export const OPEN_INVITE_USER_MODAL = 'OPEN_INVITE_USER_MODAL'
export const CLOSE_INVITE_USER_MODAL = 'CLOSE_INVITE_USER_MODAL'

export const openInviteUserModal = () => {
  store.dispatch({
    type: OPEN_INVITE_USER_MODAL,
    isInviteNewUserModalOpen: true,
  })
}

export const closeInviteUserModal = () => {
  store.dispatch({
    type: CLOSE_INVITE_USER_MODAL,
    isInviteNewUserModalOpen: false,
  })
}
