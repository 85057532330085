import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {ChatBotDeliverablesBlock} from './ChatBotDeliverablesBlock'
import {ChatBotDeliverablesBlockWithResult} from './ChatBotDeliverablesBlockWithResult'
import classes from './styles.module.scss'
import {ContactInfo} from '../../../api/model/ContactInfo'

interface ChatBotDeliverablesSectionProps {
    contactInfo: ContactInfo
}

const ChatBotDeliverablesSection: React.FC<ChatBotDeliverablesSectionProps> = ({contactInfo}) => {

  const {
    riskAssessmentQuestionnaireLink,
    familyPedigreeQuestionnaireLink,
    familyTreeLink,
    readinessRulerLink
  } = contactInfo

  const openPDFHandler = (e, viewUrl: string) => {
    e.preventDefault()
    if (viewUrl) {
      try {
        fetch(viewUrl, {method: 'GET'}).then(res => {
          if (res.ok) {
            return res.blob()
          }
        }).then(res => {
          const fileURL = URL.createObjectURL(res)
          window.open(fileURL, '_blank')
        })
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <div className={classes.deliverablesSectionWrapper}>
      <div className={classes.sectionTitle}>
            Chatbot deliverables
      </div>
      <ChatBotDeliverablesBlockWithResult
        title={'Risk Assessment Questionnaire'}
        result={'Eligible'}
        viewUrl={riskAssessmentQuestionnaireLink}
        openPDFHandler={openPDFHandler}
      />
      <ChatBotDeliverablesBlock
        title={'Family Pedigree Questionnaire'}
        viewUrl={familyPedigreeQuestionnaireLink}
        openPDFHandler={openPDFHandler}
      />
      <ChatBotDeliverablesBlock
        title={'Family Tree'}
        viewUrl={familyTreeLink}
        openPDFHandler={openPDFHandler}
      />
      <ChatBotDeliverablesBlock
        title={'Readiness Ruler'}
        viewUrl={readinessRulerLink}
        openPDFHandler={openPDFHandler}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  contactInfo: state.contactInfo
})

export default withRouter(connect(mapStateToProps)(ChatBotDeliverablesSection))
