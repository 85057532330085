import React from 'react'
import {UserInfoLeftContent} from '../leftBlock/userInfoLeftContent/UserInfoLeftContent'
import {UserInfoRightContent} from '../rightBlock/rightContent/UserInfoRightContent'
import classes from './styles.module.scss'

interface UserInfoContentProps {
    match: any
}

export const UserInfoContent: React.FC<UserInfoContentProps> = ({match}) => {

  return (
    <div className={classes.contentWrapper}>
      <UserInfoLeftContent/>
      <UserInfoRightContent match={match}/>
    </div>
  )
}
