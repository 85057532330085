import React, {MouseEvent} from 'react'
import {ViewIcon} from '../../common/icons/ViewIcon'
import classes from './styles.module.scss'

interface ChatBotDeliverablesButtonsWithResultProps {
  result: string
  viewUrl?: string
  downloadUrl?: string
  openPDFHandler: (e: MouseEvent<HTMLAnchorElement>, viewUrl: string) => void
}

export const ChatBotDeliverablesButtonsWithResult: React.FC<ChatBotDeliverablesButtonsWithResultProps> = ({
  result,
  viewUrl,
  openPDFHandler
}) => {

  return (
    <div className={classes.buttonsWrapper}>
      <a
        onClick={(e) => openPDFHandler(e, viewUrl)}
        className={viewUrl ? classes.viewButton : classes.viewButtonDisabled}
        rel="noreferrer"
      >
        <ViewIcon disabled={!viewUrl}/>
        <div className={viewUrl ? classes.text : classes.textDisabled}>
                View
        </div>
      </a>
      {viewUrl && <div className={classes.result}>
          Result: <span className={classes.resultStatus}>{result}</span>
      </div>
      }
    </div>
  )
}
