import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'
import Input from '../../../../uiKit/inputs/Input'
import {inviteNewUser} from '../../api/contactsApi'
import {connect} from 'react-redux'
import classes from './styles.module.scss'

const EMAIL_REGEX = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/
const PHONE_REGEX = /^\d{10}$/

interface InviteNewUserModalProps {
  contactsSize: number,
  match: any
}

const InviteNewUserModal: React.FC<InviteNewUserModalProps> = ({contactsSize, match}) => {
  const [isFirstAndLastNameValid, setIsFirstAndLastNameValid] = useState<boolean>(true)
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true)
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(true)
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(false)
  const [firstAndLastName, setFirstAndLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')

  const validateFirstAndLastName = (firstAndLastName?: string): boolean => {
    return firstAndLastName != null && firstAndLastName.length !== 0
  }

  const validateEmail = (email: string): boolean => {
    return EMAIL_REGEX.test(email)
  }

  const validatePhone = (phone: string): boolean => {
    return !phone || PHONE_REGEX.test(phone)
  }

  const preparePhoneToSubmit = (phone: string): string => {
    if (phone.length < 4 || !phone.includes('(')) {
      return phone
    } else if (phone.length >= 4 && phone.length < 7) {
      return `${phone.slice(1, 4)}${phone.slice(6, 9)}`
    } else {
      return `${phone.slice(1, 4)}${phone.slice(6, 9)}${phone.slice(10, 14)}`
    }
  }

  const validateFields = (
    firstAndLastName?: string,
    email?: string,
    phone?: string,
    isSubmitButtonWasClicked = false
  ): boolean => {
    const isNameValid = validateFirstAndLastName(firstAndLastName)
    setIsFirstAndLastNameValid(isNameValid)

    const isEmailValid = validateEmail(email)
    setIsEmailValid(isEmailValid)

    const isPhoneValid = validatePhone(preparePhoneToSubmit(phone))
    setIsPhoneValid(isPhoneValid)

    setDisableSubmitButton((!isNameValid || !isEmailValid || !isPhoneValid) || isSubmitButtonWasClicked)

    return isNameValid && isEmailValid && isPhoneValid
  }

  const handleFirstAndLastNameChange = (e) => {
    setFirstAndLastName(e.target.value)
    validateFields(e.target.value, email, phone)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    validateFields(firstAndLastName, e.target.value, phone)
  }

  const handlePhoneChange = (e) => {
    let value = e.target.value as string
    if (value && value.length > 10 && !value.includes('(')) value = value.slice(0, 10)
    setPhone(value)
    validateFields(firstAndLastName, email, value)
  }

  const normalizePhoneInput = (value?: string, previousValue?: string) => {
    if (!value) return value
    const currentValue = value.replace(/[^\d]/g, '')
    const cvLength = currentValue.length

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`
    }
  }

  const handleInviteNewUser = () => {
    const isAllFieldsValid = validateFields(firstAndLastName, email, phone, true)
    if (isAllFieldsValid) {
      const preparedPhone = preparePhoneToSubmit(phone)
      inviteNewUser({
        firstAndLastName,
        email,
        phoneNumber: preparedPhone
      }, match.params.botId, contactsSize)
    }
  }

  return (
    <div>
      <h2 className={classes.header}>
                    Invite user
      </h2>
      <div className={classes.inputFiledWrapper}>
        <Input
          title="Name and surname"
          placeholder="Type here"
          value={firstAndLastName || ''}
          onChange={handleFirstAndLastNameChange}
          error={!isFirstAndLastNameValid && disableSubmitButton}
          inputTitleStyles={{margin: '0px 0px 8px'}}
          errorMessage={'Please provide name and surname'}
        />
      </div>
      <div className={classes.inputFiledWrapper}>
        <Input
          title="Email address"
          placeholder="Type here"
          value={email || ''}
          onChange={handleEmailChange}
          error={!isEmailValid && disableSubmitButton}
          inputTitleStyles={{margin: '0px 0px 8px'}}
          errorMessage={'Email address is not valid'}
        />
      </div>
      <div className={classes.inputFiledWrapper}>
        <Input
          title="Phone number"
          placeholder="Type here"
          value={normalizePhoneInput(phone) || ''}
          onChange={handlePhoneChange}
          error={!isPhoneValid && disableSubmitButton}
          inputTitleStyles={{margin: '0px 0px 8px'}}
          errorMessage={'Phone number is not valid, should be 10 numbers'}
        />
      </div>
      <SubmitButton
        disabled={disableSubmitButton}
        styles={{
          width: '153px',
          height: '48px',
          margin: '30px auto 0',
          display: 'block'
        }}
        onClick={handleInviteNewUser}
        title={'Invite a new user'}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  contactsSize: state.contactsSize,
})

export default withRouter(connect(mapStateToProps)(InviteNewUserModal))
