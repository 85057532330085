import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {InfoField} from '../../common/InfoField'
import classes from './styles.module.scss'
import {ContactInfo} from '../../../api/model/ContactInfo'

export const preparePhoneNumber = (phone: string): string => {
  return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`
}

interface GeneralInfoSectionProps {
    contactInfo: ContactInfo
}

const GeneralInfoSection: React.FC<GeneralInfoSectionProps> = ({contactInfo}) => {

  const {
    firstAndLastName,
    username,
    email,
    phone
  } = contactInfo
  return (
    <>
      <div className={classes.userName}>
        {firstAndLastName}
      </div>
      <InfoField
        title={'User name'}
        value={username}
      />
      <InfoField
        title={'Email address'}
        value={email}
      />
      <InfoField
        title={'Phone number'}
        value={phone ? preparePhoneNumber(phone) : 'NONE'}
      />
    </>
  )
}

const mapStateToProps = state => ({
  contactInfo: state.contactInfo
})

export default withRouter(connect(mapStateToProps)(GeneralInfoSection))
