import React, {useState} from 'react'
import {CustomButton} from '../../common/CustomButton'
import {ScheduleResultsDeliveryModal} from '../../modal/ScheduleResultsDeliveryModal'
import Modal from '../../../../../uiKit/Modal'
import classes from './styles.module.scss'
import {ContactInfo, GtKitInfo, GtKitOption, ResultsDeliveryInfo} from '../../../api/model/ContactInfo'
import {connect} from 'react-redux'
import ResultStatusComponent from './ResultStatusComponent'
import {formatDate} from '../confirmResultsSection/ConfirmResultsSection'

interface PostTestSectionProps {
    match: any
    contactInfo: ContactInfo
    gtKitInfo: GtKitInfo
    resultsDeliveryInfo: ResultsDeliveryInfo
}

const PostTestSection: React.FC<PostTestSectionProps> = ({match, contactInfo, gtKitInfo, resultsDeliveryInfo}) => {
  const [scheduleModalOpen, setScheduleModalOpen] = useState<boolean>(false)

  const {
    isRequiredStatus
  } = contactInfo

  const {
    gtKitOption
  } = gtKitInfo

  const {
    isResultsDeliveryScheduled,
    resultStatus,
    positiveResultsDeliveryConfirmationTimestamp
  } = resultsDeliveryInfo

  const handleClick = (e) => {
    setScheduleModalOpen(true)
  }

  const handleModalClose = () => {
    setScheduleModalOpen(false)
  }

  return (
    <div className={classes.wrapper}>
      <Modal
        classes={{paper: classes.paperModal}}
        open={scheduleModalOpen}
        onClose={handleModalClose}>
        <ScheduleResultsDeliveryModal
          match={match}
          onClose={handleModalClose}
        />
      </Modal>
      <span className={classes.text}>
          Post-test
      </span>
      <div className={classes.wrapperBottom} style={{ width: '600px' }}>
        <div className={classes.wrapperBottomLeft}>
          <span className={classes.textLight}>
          Deliver test results
          </span>
          {isResultsDeliveryScheduled && <ResultStatusComponent resultStatus={resultStatus}/>}
        </div>
        <div className={classes.wrapperBottom} style={{ width: '1300px' }}>
          <CustomButton
            text={isResultsDeliveryScheduled ? 'Results delivery scheduled' : 'Schedule results delivery'}
            disabled={!isRequiredStatus || gtKitOption != GtKitOption.CONFIRMED}
            fulled={isResultsDeliveryScheduled}
            onBtnClick={handleClick}
          />
          {isResultsDeliveryScheduled &&
                <span className={classes.dateTime}>{formatDate(positiveResultsDeliveryConfirmationTimestamp)}</span>}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  contactInfo: state.contactInfo,
  gtKitInfo: state.gtKitInfo,
  resultsDeliveryInfo: state.resultsDeliveryInfo,
})

export default connect(mapStateToProps)(PostTestSection)
