import React from 'react'
import GeneralInfoSection from '../general_info/GeneralInfoSection'
import ChatBotDeliverablesSection from '../chatbot_deliverables/ChatBotDeliverablesSection'
import classes from './styles.module.scss'

export const UserInfoLeftContent: React.FC = ({}) => {
  return (
    <div className={classes.wrapper}>
      <GeneralInfoSection/>
      <ChatBotDeliverablesSection/>
    </div>
  )
}
