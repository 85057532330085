import React, {MouseEvent} from 'react'
import {ChatBotDeliverablesButtons} from './ChatBotDeliverablesButtons'
import classes from './styles.module.scss'

interface ChatBotDeliverablesBlockProps {
    title: string
    viewUrl?: string
    downloadUrl?: string
    openPDFHandler: (e: MouseEvent<HTMLAnchorElement>, viewUrl: string) => void
}

export const ChatBotDeliverablesBlock: React.FC<ChatBotDeliverablesBlockProps> = ({
  title,
  viewUrl,
  downloadUrl,
  openPDFHandler
}) => {

  return (
    <div className={classes.sectionWrapper}>
      <div className={classes.title}>
        {title}
      </div>
      <ChatBotDeliverablesButtons
        viewUrl={viewUrl}
        downloadUrl={downloadUrl}
        openPDFHandler={openPDFHandler}
      />
    </div>
  )
}
